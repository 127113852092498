import React from "react";
import { Link } from "gatsby";

import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import SeoPrivacyPolicy from "../components/SeoPrivacyPolicy";
import CookieBanner from "../components/CookieBanner";

const PrivacyPolicyPage = () => {
  return (
    <>
      <div id="top" name="top" className="bg-gray">
        <SeoPrivacyPolicy />
        <Header />
        <main className="container flex flex-col h-fit w-full py-10 md:py-10 xl:py-10 ">
          <div className="flex flex-col gap-5 text-center mb-10">
            <h1 className="text-4xl">Slashscore's Privacy Policy</h1>
            <h4 className="text-xl">Updated November 3, 2022</h4>
          </div>

          <div className="mb-10">
            <p className="body2">
              The following Privacy Policy is valid from and was last updated on November 3, 2022.
            </p>
            <p className="body2">
              Your personal information will be handled by our company, WIN GRAVITY SRL, which runs the Slashscore app
              and has its registered office at Iasi, Dacia Street, No. 1, Romania.
            </p>
            <p className="body2">
              We do not underestimate the protection of your personal data and your privacy, and we make every effort to
              ensure that they are secured sufficiently. We handle personal data entirely in compliance with the legal
              regulations in force.
            </p>
            <p className="body2">
              The personal data processing rules contained in this policy correspond to the obligations imposed on us in
              the processing of personal data by Regulation (EU) 2016/679 of the European Parliament and of the Council
              of April 27, 2016 on the protection of natural persons with regard to the processing of personal data and
              on the free movement of such data and repealing Directive 95/46/EC, the EC-General Data Protection
              Regulation (hereinafter referred to as "GDPR").
            </p>
            <p className="body2">
              We will clarify in this document which personal data we collect, for what purpose, how we use them, what
              we do so that they are safe, and which rights you can exercise towards us.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Scope of policy</h2>
            <p className="body2">
              This policy (together with our terms of service) is available&nbsp;
              <Link to="/terms-of-service" className="underline">
                here
              </Link>
              applies to your use of:
            </p>
            <ul className="list-disc pl-14">
              <li className="body2">
                “Slashscore” application software, or any other branded version of Slashscore which contains either this
                Privacy Policy or a link to this Privacy Policy once you have accessed Slashscore on your mobile phone
                or handheld device (Device); and
              </li>
              <li className="body2">
                Any of the services that are accessible through Slashscore (Services) based on the content of
                third-party platforms hosting accounts you notify us of, and information provided by you while
                completing your Slashscore account.
              </li>
            </ul>
            <p className="body2">
              Take note, in particular, that we may use your data in the near future to propose partnerships with users
              of new additional Slashscore products and modules that are currently in development.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Information we may collect from you</h2>
            <p className="body2">We may collect and process the following data about you:</p>
            <ul className="list-disc pl-14">
              <li className="body2">
                Information you give us (Submitted information): You may give us information about yourself by
                completing your profile or filling in forms on Slashscore, or by corresponding with us (for example, by
                e-mail). Some of them are collected automatically with your consent, such as using cookies when you
                visit our website. This includes information you provide when you register to use Slashscore, subscribe
                to any of our services, share data via Slashscore’s social media functions, enter a competition,
                promotion, or survey, and when you report a problem with Slashscore. This information may include your
                name, address, email address, and access to your public Github, Gitlab, Bitbucket, Wakatime, Medium,
                Dev.to, Stackoverflow, Meetup, Twitter, YouTube, Reddit, or other platform accounts integrated into
                Slashscore, as well as your username, password, and other registration information, personal
                description, and photograph.
              </li>
              <li className="body2">
                We collect information about you and your device. Each time you use Slashscore we may automatically
                collect the following information:
              </li>
              <ul className="list-circle pl-20">
                <li className="body2">
                  Technical information, including the type of device you use (model, manufacture, and library version),
                  a unique device identifier (for example, your device’s IMEI number, the MAC address of the device’s
                  wireless network interface), mobile network information, your mobile operating system, the type of
                  mobile browser you use, time zone setting, (Device Information);
                </li>
                <li className="body2">
                  Public information contained in your Github, Gitlab, Bitbucket, Wakatime, Medium, Dev.to,
                  Stackoverflow, Meetup, Twitter, YouTube, Reddit, or other platform integrated into Slashscore account
                  (if you permit us to access them) or other accounts we ask your permission to access (social
                  Information);
                </li>
                <li className="body2">
                  Details of your use of Slashscore and the resources and features that you access.
                </li>
              </ul>
            </ul>
            <p className="body2">
              We will always inform you about the specific reason for processing your personal information. This
              information is stated directly in the terms of the service provided or in this policy. Alternatively, you
              may ask us at any time for the reasons for processing your personal information through the contact
              details listed below.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Uses made of the information</h2>
            <p className="body2">We use information held about you in the following ways:</p>
            <ul className="list-disc pl-14">
              <li className="body2">
                <span className="font-semibold">Submitted Information:</span> This information will be used to provide
                you with Slashscore's various features and to personalize your experience; it will also be used to
                display your profile to users of new Slashscore products/modules that are currently in development. We
                also anonymize and aggregate the information such that it can no longer identify you as an individual.
                We do this for various purposes, including research and improving our services.
              </li>
              <li className="body2">
                <span className="font-semibold">Device information:</span> We use this to better understand who uses
                Slashscore and quickly understand if bugs are related to specific browsers or device sizes.
              </li>
              <li className="body2">
                <span className="font-semibold">Social Information:</span> We use this to build your Slashscore profile.
                We also anonymize and aggregate the information such that it can no longer identify you as an
                individual. We do this for various purposes, including research and improving our services.
              </li>
              <li className="body2">
                <span className="font-semibold">Log information:</span> we use this to optimize and further develop
                Slashscore for the benefit of us and all our users. We also anonymize and aggregate the information such
                that it can no longer identify you as an individual. We do this for various purposes, including research
                and improving our services.
              </li>
            </ul>
            <p className="body2">
              We may associate any category of information with any other category of information and will treat the
              combined information as personal data in accordance with this policy for as long as it is combined.
            </p>
            <p className="body2">
              If we have your consent, we may also use your personal data to provide you with marketing messages about
              other products and services we provide that may be of interest to you.
            </p>
            <p className="body2">
              Also, we may provide various third parties with anonymous aggregate information about our users (for
              example, we may inform them that 200 women aged under 30 have been seniors in Java on any given
              information). This data will not identify you as an individual.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Disclosure of your information</h2>
            <p className="body2">
              We will not share your personal information with anyone except as described in this policy.
            </p>
            <p className="body2">
              Your personal data will be accessed by our company's employees, who will be in charge of working with this
              personal information. All employees who will have access to your personal data are committed to secrecy in
              writing; therefore, your personal data may not be disseminated anywhere.
            </p>
            <p className="body2">
              We will then pass on your personal information to some third parties if necessary. These persons are
              referred to as "processors." Our company is responsible for ensuring that these processors provide
              reasonable assurance that your personal data will be processed. We choose all of the processors
              responsibly. At the same time, the processors will be contractually obliged to perform all their duties,
              ensuring that your personal data is adequately protected and minimizing the risk of abuse.
            </p>
            <p className="body2">We may disclose your personal information to third parties:</p>
            <ul className="list-disc pl-14">
              <li className="body2">
                Our accounting, legal, tax, marketing and other similar advisors to whom we pass on your personal
                information if it is necessary in order to use their services - consultation. Furthermore, IT and
                hosting providers.
              </li>
              <li className="body2">
                In order to improve our services or ensure their smooth delivery, we may pass on your personal
                information to those whose online tools we use - but only for the purpose of addressing or improving the
                quality of our services:
              </li>

              <ul className="list-circle pl-20">
                <li className="body2">
                  <a
                    href="https://marketingplatform.google.com/about/analytics/"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google
                  </a>
                  &nbsp; (US) Web behavior analysis, Online advertising on the AdWords network
                </li>
                <li className="body2">
                  <a href="https://mailchimp.com/en-gb/" className="underline" target="_blank" rel="noreferrer">
                    Mailchimp
                  </a>
                  &nbsp; (US) E-mail marketing
                </li>
                <li className="body2">
                  <a href="https://vercel.com/" className="underline" target="_blank" rel="noreferrer">
                    Vercel
                  </a>
                  &nbsp; (US) end-to-end platform for developers, that allows us to create and deploy your web
                  application.
                </li>
                <li className="body2">
                  <a href="https://aws.amazon.com/" className="underline" target="_blank" rel="noreferrer">
                    AWS
                  </a>
                  &nbsp; (US) purpose-built tools and services for our web application.
                </li>
              </ul>
              <li className="body2">
                Any member of our group, which means our subsidiaries, our ultimate holding company, and its
                subsidiaries.
              </li>
              <li className="body2">
                In the event that we sell or buy any business or assets, in which case we may disclose your personal
                data to the prospective seller or buyer of such business or assets.
              </li>
              <li className="body2">
                If Slashscore or substantially all of its assets are acquired by a third party, in which case the
                personal data held by it about its customers will be one of the transferred assets.
              </li>
              <li className="body2">
                If we are under a duty to disclose or share your personal data in order to comply with any legal or
                regulatory obligation or request.
              </li>
              <li className="body2">In order to</li>
              <ul className="list-circle pl-20">
                <li className="body2">
                  enforce or apply our Terms of Use and other agreements, or to investigate potential breaches; or
                </li>
                <li className="body2">protect the rights, property, or safety of Slashscore, our users, or others. </li>
              </ul>
            </ul>
            <p className="body2">
              If you consent to this, we may also provide certain information to selected third parties to inform you of
              the services and products that we or selected third parties offer and which may be of interest to you.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">How long will we retain your data?</h2>
            <p className="body2">
              We won't keep your personal information for longer than is needed for the purposes for which it was
              collected or processed. We'll use legal or regulatory requirements, statutory retention periods, and
              guidance from regulatory bodies like the Information Commissioner's Office to decide what data to keep
              from this period. For example, we delete any personally identifiable log data when you delete your
              Slashscore account or 2 years after your last use of Slashscore.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Marketing</h2>
            <p className="body2">
              You have the right to ask us not to process your personal data for marketing purposes. We will inform you
              (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose
              your information to any third party for such purposes. If you are using a third-party-branded version of
              the app, this includes disclosure to the owner of, or companies doing business under, that brand. You can
              exercise your right to prevent such processing by checking certain boxes on the forms we use to collect
              your data. You can also exercise this right at any time by contacting us at contact@slashscore.com.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Deleting your Slashscore account</h2>
            <p className="body2">
              You can delete your account at any time by using the 'Delete Account’ button in the Account Settings.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Transfers of data outside Europe</h2>
            <p className="body2">
              The information that you provide to us may be transferred to countries outside the European Economic Area
              (“EEA”). These countries may not have similar data protection laws to those in the EEA. If we transfer
              your information outside of the EU in this way, we will take steps to ensure that appropriate security
              measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined
              in this policy.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Your rights</h2>
            <p className="body2">You have a number of rights regarding our use of your data.</p>
            <ul className="list-disc pl-14">
              <li className="body2">
                Upon written request, we will provide you with a copy of the personal data that we keep about you,
                subject to any exceptions under data protection laws (for example, if the data includes information
                about other people). We'll get back to you within the time limit set by the data protection law in
                effect, which is usually one month after we get your written request. We will provide the information
                without charge, but we may charge a reasonable fee for the administrative cost of providing the
                information if the request for information is excessive.
              </li>
              <li className="body2">
                You can require us to correct any mistakes in your personal data that we hold about you. You can request
                a copy of your information that we hold (this is known as a subject access request). To the extent
                required by data protection laws, we will provide you with a readable copy of the personal data that we
                keep about you. We'll get back to you within the time limit set by the law on data protection, which is
                usually one month after we receive your request.
              </li>
            </ul>
            <p className="body2">
              However, we have the right to require verification of your identity to verify that this information
              regarding your personal data does not reach an unauthorized person.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">You can ask us to stop contacting you for direct marketing purposes.</h2>

            <ul className="list-disc pl-14">
              <li className="body2">
                You can require us to erase the personal information we hold about you where: the personal data is no
                longer necessary in relation to the purposes for which it was collected or otherwise processed; you
                object to the processing and there is no overriding legitimate interest for us to continue the
                processing of the data; your personal data was unlawfully processed, or your personal data must be
                erased in order to comply with a legal obligation.
              </li>
              <li className="body2">
                You have the right to restrict the processing of your personal data where: you contest the accuracy of
                the personal data; you have objected to our processing of your data, and we are considering your
                objection because we have said the processing is necessary for the purpose of our legitimate interests;
                the processing is unlawful and you do not wish for the data to be erased but require restricted
                processing of the data instead; or we no longer require your personal data but you require the data to
                establish, exercise, or defend a legal claim.
              </li>
              <li className="body2">
                You have the right to object to our processing of your personal data where our legal basis for
                processing the data is for the purposes of our legitimate interests or for the performance of a task
                carried out in the public interest. We may refuse if there are compelling legitimate grounds for the
                processing that override your interests, rights, and freedoms, or if the processing is necessary for the
                exercise or defense of legal claims.
              </li>
            </ul>
            <p className="body2">
              Should you wish to exercise these rights, then you can write to us at contact@slashscore.com.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Changes to the privacy policy</h2>
            <p className="body2">
              Any changes we may make to our privacy policy in the future will be posted on this page and, where
              appropriate, notified to you when you next start Slashscore. The new terms may be displayed on-screen, and
              you may be required to read and accept them to continue your use of Slashscore.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">Contact</h2>
            <p className="body2">
              If you have any questions, comments, or requests about this privacy policy, you can send them to
              contact@slashscore.com.
            </p>
          </div>
        </main>
        <Footer arrowLink="/privacy-policy#top" />
        <CookieBanner />
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
